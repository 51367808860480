import React from "react"

import PageWrapper from "../../components/PageWrapper"
import ExternalLink from "../../components/basic/ExternalLink"
import ContactSection from "../../components/content/ContactSection"
import ProductOverviewSection from "../../components/content/ProductOverviewSection"
import { faMale, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FuerDenMenschPage = () => {
  return (
    <PageWrapper title="Produkte für den Menschen" >
      <section id="produkte" className="mt-5">
        <h2>
          <FontAwesomeIcon icon={faMale} className="mr-3 ml-1"/>
          Für den Mensch
        </h2>
        <h5 className="mb-4" style={{color: "rgba(0,0,0,0.7)"}}>
          Gesunde Tiere brauchen auch gesunde Menschen
        </h5>
        <div style={{maxWidth: 800}}>
          <p className="mb-2">
          Hier finden Sie alles für Ihr persönliches Wohlbefinden. Die reinen Naturprodukte von Reico für den Menschen sorgen für Vitalität, Energie und Gesundheit im Alltag, ganz ohne chemische Zusätze.
          </p>

          <p className="mb-4" style={{fontWeight: "700"}}>Bringen auch Sie sich ins mineralische Gleichgewicht! </p>

          <p>

          Wenn Sie sich für eines der Produkte interessieren,
          können Sie die Produkte direkt bei <ExternalLink href="#contact" text="mir" /> bestellen, sich kostenlos beraten lassen und als Kunde erhalten
          Sie dann auch ihre eigenen Zugangsdaten um jederzeit selbst Bestellungen zu tätigen.

          </p>
        </div>
        <ProductOverviewSection target="Human" />
      </section>
      <hr className="my-5"/>
      <ContactSection />
    </PageWrapper>
  )
}

export default FuerDenMenschPage
